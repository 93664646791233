import React, { useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from '../../config/axios';

import {
  Row,
  Label,
  StyledTextArea,
  StyledInput,
  StyledSelect,
} from '../../components/FormFields/FieldStyle';
import Layout from '../../components/Layout';
import LayoutContext from '../../context/LayoutContext';
import PageTitle from '../../components/PageTitle/PageTitle';

const Form = styled.form`
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 768px) {
    padding: 20px;
    ${(props) =>
    props.theme.name === 'men' ? 'border: 1px solid #7f7f7f;' : ''}
  }

  background: ${(props) => props.theme.fombBgColor};

  @media screen and (max-width: 767px) {
    padding: 20px 10px;
  }
`;

const Header = styled.h1`
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: bold !important;
`;
const Desc = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.descColor};
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    padding: 20px 15px;
    margin: 0;
  }
`;

const Submit = styled.input`
  cursor: pointer;
  display: table;
  padding: 0 10px;
  margin: 20px 0 40px auto;
  font-size: 12px;
  color: #ffffff;
  background: ${(props) => props.theme.submitBgColor};
  border: none;

  &:hover {
    background: ${(props) => props.theme.submitHoverBgColor};
  }
`;

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(146, 146, 146, 0.48);
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Tnc = styled.div`
  display: block;
  color: #848484;
  font-size: 12px;
`;

const ErrorMsg = ({ children }) => {
  return <div className="invalid-feedback d-block">{children}</div>;
};

const ContactUs = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch, errors } = useForm();
  const email = useRef({});
  email.current = watch('email', '');
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const { isMobileView } = useSelector((state) => state.common);
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    axios
      .post(process.env.AXIOS_BASE_URL + 'ajax/contactus/submit', data)
      .then(function (response) {
        setIsLoading(false);
        // console.log(response)
        if (response.data.code == 200) {
          setIsFormSubmit(true);
        } else {
          alert(response.message);
        }
      })
      .catch(function (error) {
        if (error != null) {
          // console.log(error);
          alert(error);
        }
        else {
          alert('success')
        }
        setIsLoading(false);
      });

    // alert(JSON.stringify(data));
  };

  return (
    <Layout
      seoSettings={{ title: t('contact_us') }}
      fullwidth={isMobileView}
      className="position-relative"
    >
      {isLoading && <Loading />}
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          {t('contact_us')}
        </PageTitle>
      ) : (
        <Header>{t('contact_us')}</Header>
      )}

      {isFormSubmit ? (
        <Desc>
          {/* {t('contact_us_desc')}
          <br /> */}
          {t('contact_us_success')}
        </Desc>
      ) : (
        <>
          <Desc>{t('contact_us_desc')}</Desc>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Container className="form-row justify-content-between">
              <Row className="col-12">
                <Label isRequired={true}>{t('form_message')}</Label>
                <StyledTextArea
                  name="message"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', { name: t('form_message') }),
                    },
                  })}
                  rows={5}
                />
                {errors.message && (
                  <ErrorMsg>{errors.message.message}</ErrorMsg>
                )}
              </Row>
              <Row className="col-md-6">
                <Label isRequired={true}>{t('form_firstname')}</Label>
                <StyledInput
                  name="first_name"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', {
                        name: t('form_firstname'),
                      }),
                    },
                  })}
                />
                {errors.first_name && (
                  <ErrorMsg>{errors.first_name.message}</ErrorMsg>
                )}
              </Row>
              <Row className="col-md-6">
                <Label isRequired={true}>{t('form_lastname')}</Label>
                <StyledInput
                  name="last_name"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', {
                        name: t('form_lastname'),
                      }),
                    },
                  })}
                />
                {errors.last_name && (
                  <ErrorMsg>{errors.last_name.message}</ErrorMsg>
                )}
              </Row>
              <Row className="col-12">
                <Label isRequired={true}>{t('form_phone')}</Label>
                {/* <div className="form-row justify-content-between"> */}
                {/* <div className="col-3 col-md-2"> */}
                <StyledSelect name="country_code" data-field="country_code" class="form-control custom-select country_code" ref={register({
                  required: {
                    value: true,
                    message: t('field_required', { name: t('form_phone') }),
                  },
                })}>
                  <option value="852" selected="">+852</option>
                  <option value="853">+853</option>
                  <option value="86">+86</option>
                </StyledSelect>
                {/* </div> */}
                {/* <div className="col-9 col-md-10"> */}
                <StyledInput
                  name="mobile"
                  type="number"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', { name: t('form_phone') }),
                    },
                    min: {
                      value: 8,
                      message: t('field_mobile_min'),
                    },
                  })}
                />
                {/* </div> */}
                {/* </div> */}
                {errors.mobile && <ErrorMsg>{errors.mobile.message}</ErrorMsg>}
              </Row>
              <Row className="col-md-6">
                <Label isRequired={true}>{t('form_email')}</Label>
                <StyledInput
                  name="email"
                  type="email"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', { name: t('form_email') }),
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('invalid_email_format'),
                    },
                  })}
                />

                {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
              </Row>
              <Row className="col-md-6">
                <Label isRequired={true}>{t('form_confirm_email')}</Label>
                <StyledInput
                  name="EMAIL2"
                  type="email"
                  ref={register({
                    required: {
                      value: true,
                      message: t('field_required', {
                        name: t('form_confirm_email'),
                      }),
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('invalid_email_format'),
                    },
                    validate: (value) =>
                      value === email.current ||
                      t('field_not_match', { name: t('form_confirm_email') }),
                  })}
                />
                {errors.EMAIL2 && <ErrorMsg>{errors.EMAIL2.message}</ErrorMsg>}
              </Row>

              <Tnc>{t('form_required')}</Tnc>
            </Container>
            <Submit type="submit" value={t('form_submit')} />
          </Form>
        </>
      )}
    </Layout>
  );
};

export default ContactUs;
